export const useCoreStore = defineStore('core', {
    state: () => ({
        version: __APP_VERSION__,
        darkMode: useDark(),
        appMounted: false,
        coreLoaded: false,
        vendedorAsignado: <App.Models.User | null>null,
        vendedores: <App.Models.User[]>[],
        clientes: <App.Models.User[]>[],
        rawTables: <NuevoPedido.Tables>[],
    }),

    getters: {
        isDark: state => state.darkMode,
    },

    actions: {
        setAppMounted() {
            this.appMounted = true
        },

        toggleDark() {
            this.darkMode = !this.darkMode
        },

        async fetchCoreData(force = false) {
            if (!this.coreLoaded || force) {
                this.appMounted = false
                this.coreLoaded = false

                await window.axios
                    .get<Api.ApiCoreResponse>('/api/core')
                    .then(({ data }) => {
                        this.vendedorAsignado = data.vendedor_asignado
                        this.vendedores = data.vendedores
                        this.clientes = data.clientes
                        this.rawTables = data.pedidos_table

                        this.coreLoaded = true
                        this.setAppMounted()
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        },

        async cacheClear() {
            const authStore = useAuthStore()

            if (authStore.admin) {
                await window.axios
                    .get('/api/core/cache-clear')
                    .then(() => {
                        pushNotification.success('Cache purgado')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }

            await this.fetchCoreData(true)
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot))
}
